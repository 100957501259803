import { getToken, onMessage } from 'firebase/messaging';
import { messaging, messagingKey } from '../../Configs/firebase.config';
import { store } from '../../Store';
import ToastController from '../ToastController';
import { onApplyJob, onEmployeeRegistred, onInviteAccept, onInviteReject, onMsgEmployer, setFirebaseToken, setMessage, setNotifications } from '../../Store/Slices/NotificationSlice';
import ApiService from '../../Services/ApiService';
import { useEffect, useState } from 'react';
import { useAuth } from '../AuthController';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setPushConversationCount } from '../../Store/Slices/ConversationSlice';
import { isMessgingSupported } from '../../Configs/firebase.config';

class NotificationController {
  // Request permission for push notifications

  static NOTIFICATION_FLAGS = {
    APPLY_ON_JOB: 'APPLY_ON_JOB',
    INVITE_ACCEPTED: 'INVITE_ACCEPTED',
    INVITE_REJECTED: 'INVITE_REJECTED',
    SEND_MSG_TO_EMPLOYER: 'SEND_MSG_TO_EMPLOYER',
    EMPLOYEE_REGISTERED: 'EMPLOYEE_REGISTERED',
  };

  static getToken(_callback = () => false) {
    return new Promise((resolve, reject) => {
      if (!isMessgingSupported) {
        reject('Messaging Unsupported!');
      }
      getToken(messaging, { vapidKey: messagingKey })
        .then(token => {
          console.log(token, '@token  .!');
          store.dispatch(setFirebaseToken(token));
          _callback(token);
          resolve(token);
        })
        .catch(err => {
          _callback(false);
          console.log(err, '@token  error.!');
          reject(err);
        });
    });
  }

  static dispatchNotification(type, payload) {
    switch (type) {
      case NotificationController.NOTIFICATION_FLAGS.APPLY_ON_JOB:
        store.dispatch(onApplyJob(payload));
        return;
      case NotificationController.NOTIFICATION_FLAGS.EMPLOYEE_REGISTERED:
        store.dispatch(onEmployeeRegistred(payload));
        return;
      case NotificationController.NOTIFICATION_FLAGS.INVITE_ACCEPTED:
        store.dispatch(onInviteAccept(payload));
        return;
      case NotificationController.NOTIFICATION_FLAGS.INVITE_REJECTED:
        store.dispatch(onInviteReject(payload));
        return;
      case NotificationController.NOTIFICATION_FLAGS.SEND_MSG_TO_EMPLOYER:
        console.log('notification running..!');
        store.dispatch(onMsgEmployer(payload));
        store.dispatch(setPushConversationCount(payload));
        return;
      default:
        return;
    }
  }

  static onNotification(userId) {
    if (isMessgingSupported) {
      onMessage(messaging, payload => {
        console.log(payload, '@messages recived.!');
        store.dispatch(setMessage(true));
        NotificationController.dispatchNotification(payload?.data?.type, payload);
        NotificationController.getAll(userId);
        ToastController.success(payload?.notification?.title);
      });
    } else {
      return null;
    }
  }

  static saveToken(userId, token) {
    const data = {
      value: token,
    };
    return ApiService.fetchData({
      url: `/employer/auth/save-fcm-token/${userId}`,
      method: 'patch',
      data,
    });
  }

  static removeToken(userId, token) {
    const data = {
      value: token,
    };
    return ApiService.fetchData({
      url: `/employer/auth/delete-fcm-token/${userId}`,
      method: 'delete',
      data,
    });
  }

  static getAll(employerId) {
    return ApiService.fetchData({
      url: `/employer/notification/get-all-notification/${employerId}`,
      method: 'get',
    });
  }

  static markAllRead(employerId) {
    return ApiService.fetchData({
      url: `/employer/notification/mark-as-read-notification/${employerId}`,
      method: 'patch',
    });
  }

  static markSingleRead(notificationId) {
    return ApiService.fetchData({
      url: `/employer/notification/${notificationId}/mark-as-read-notification`,
      method: 'patch',
    });
  }

  static markConversationRead(channelId, conversationId) {
    return ApiService.fetchData({
      url: `/employer/notification/${channelId}/mark-as-read-conversation-notification/${conversationId}`,
      method: 'patch',
    });
  }

  // Call the function to request permission
}

export default NotificationController;

export const useNotifications = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const navigate = useNavigate();

  const handleNavigateByType = notification => {
    console.log('navigate type ', notification);
    switch (notification?.data?.type) {
      case NotificationController.NOTIFICATION_FLAGS.EMPLOYEE_REGISTERED:
        return navigate(`/home`);
      case NotificationController.NOTIFICATION_FLAGS.APPLY_ON_JOB:
        return navigate(`/jobs-resident`);
      case NotificationController.NOTIFICATION_FLAGS.INVITE_ACCEPTED:
        return navigate(`/messages`);
      case NotificationController.NOTIFICATION_FLAGS.SEND_MSG_TO_EMPLOYER:
        return navigate(`/messages?conversationId=${notification?.data?.conversation}`);
      case NotificationController.NOTIFICATION_FLAGS.INVITE_REJECTED:
        return navigate(`/jobs-resident`);
      default:
        return;
    }
  };

  const dispatch = useDispatch();
  const { notifications, matchNotification, newMessage, conversationNotification, messageNotification, notificationToken } = useSelector(state => state.Notification);

  const handleGetNotifications = async () => {
    try {
      setLoading(true);
      const data = await NotificationController.getAll(user?._id);
      console.log(data, '@notifications.!');
      dispatch(setNotifications(data?.data));
    } catch (error) {
      ToastController.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  console.log('@meesages...', newMessage);

  function checkMarkAsRead(array) {
    for (let i = 0; i < array?.length; i++) {
      if (!array[i]?.data?.markAsRead) {
        return true;
      }
    }
    return false;
  }
  const isNotification = checkMarkAsRead(notifications?.notifications);

  const totalUnreadMessages = notifications?.notifications?.filter(noti => !noti?.data?.markAsRead && noti?.data?.type === NotificationController.NOTIFICATION_FLAGS.SEND_MSG_TO_EMPLOYER)?.length;
  console.log(totalUnreadMessages, notifications?.notifications, '@redux notifications');

  useEffect(() => {
    handleGetNotifications();
  }, [matchNotification, conversationNotification, messageNotification, notificationToken]);

  return {
    notifications: notifications?.notifications || [],
    handleNavigateByType,
    loading,
    error,
    isNotification,
    totalUnreadMessages,
    newMessage: newMessage,
  };
};
