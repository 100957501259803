import { useEffect, useMemo, useState } from 'react';
import ApiService from '../../Services/ApiService';
import ToastController from '../ToastController';
import { useDispatch, useSelector } from 'react-redux';
import { setMetaData } from '../../Store/Slices/UtilitySlice';
import { useAuth } from '../AuthController';

class UtilityController {
  static uploadSingleMedial(data) {
    const formData = new FormData();
    formData.append('image', data);
    return ApiService.fetchData({
      url: '/uploader/upload-single-image',
      method: 'post',
      data: formData,
    });
  }

  static uploadMultipleMedial(data) {
    const formData = new FormData();

    for (let index = 0; index < data?.length; index++) {
      const media = data[index];
      formData.append(`media`, media);
    }

    return ApiService.fetchData({
      url: '/uploader/upload-multiple-imgs-videos',
      method: 'post',
      data: formData,
    });
  }

  static getSpecialties(qryText) {
    const qryParam = { queryText: qryText };
    return ApiService.fetchData({
      url: '/enums/search-specialty',
      method: 'get',
      params: qryParam,
    });
  }

  static getSubSpecialties(qryParam = { queryText: '', specialtiesIds: '' }) {
    return ApiService.fetchData({
      url: '/enums/search-subSpecialty',
      method: 'get',
      params: qryParam,
    });
  }

  static randomIdGenerator() {
    return Math.floor(Math.random() * 123456);
  }

  static getMetaData() {
    return ApiService.fetchData({
      url: '/enums/get-all-enums',
      method: 'get',
    });
  }
  static getMetaLocations(qryParam = { queryText: '', pageSize: 10, pageNumber: 1 }) {
    return ApiService.fetchData({
      url: '/enums/search-state',
      method: 'get',
      params: qryParam,
    });
  }
}

export default UtilityController;

export const useSpecialties = () => {
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [specialties, setSpecialties] = useState([]);
  const [subSpecialties, setSubSpecialties] = useState([]);

  const handleGetSpecialties = async qryText => {
    try {
      setLoading(true);
      const data = await UtilityController.getSpecialties(qryText);
      setSpecialties(data?.data);
    } catch (error) {
      ToastController.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetSubSpecialties = async (qryText, specialtiesIds) => {
    const qryParam = { queryText: qryText, specialtiesIds: specialtiesIds };
    try {
      setProcessing(true);
      const data = await UtilityController.getSubSpecialties(qryParam);
      setSubSpecialties(data?.data);
    } catch (error) {
      ToastController.error(error);
    } finally {
      setProcessing(false);
    }
  };

  // useEffect(() => {
  //   handleGetSpecialties();
  //   // handleGetSubSpecialties();
  // }, []);
  return { handleGetSpecialties, handleGetSubSpecialties, specialties, subSpecialties, specialtyLoading: loading, subSpecialtyLoading: processing };
};

export const useGetLocationStates = qryText => {
  const [loading, setLoading] = useState(false);

  const [statesLocation, setStatesLocation] = useState([]);

  console.log(statesLocation, '@states Locations');

  const handleGetStates = async () => {
    // const qryParam = { queryText: qryText, specialtiesIds: specialtiesIds };
    try {
      setLoading(true);
      const data = await UtilityController.getMetaLocations(qryText);
      setStatesLocation(data?.data?.states);
    } catch (error) {
      ToastController.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetStates();
  }, [qryText?.queryText]);
  return { StatesLocation: statesLocation, loading: loading };
};

export const useMetaData = (specialtyIds = []) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const metaData = useSelector(state => state.Utility.metaData);
  const { user } = useAuth();
  const subSpecialities = useMemo(() => metaData?.subSpecialties?.filter(item => specialtyIds?.includes(item?.specialtyId)), [specialtyIds, metaData?.subSpecialities]);
  console.log(metaData, '@meta data');
  const handleGetMetaData = async () => {
    try {
      setLoading(true);
      const res = await UtilityController.getMetaData();
      console.log(res.data, '@meta data ...!');
      dispatch(setMetaData(res?.data));
    } catch (error) {
      ToastController.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!metaData && !!user) {
      handleGetMetaData();
    }
  }, []);
  function sortByTitle(arr) {
    if (arr?.length > 0) {
      const sortedArray = arr.slice().sort((a, b) => a.title.localeCompare(b.title));
      return sortedArray;
    }
    return [];
  }
  const sortedSpeciality = sortByTitle(metaData?.specialtiesAndSubSpecialty);
  return {
    loading,
    bonus: metaData?.bonus || [],
    fringeBenefits: metaData?.fringeBenefits || [],
    requiredQualification: metaData?.requiredQualification || [],
    specialties: metaData?.specialties || [],
    // arrayOfObjects.sort((a, b) => a.title.localeCompare(b.title))
    subSpecialties: subSpecialities || [],
    specialtiesAndSubspecialties: sortedSpeciality || [],
    employmentType: metaData?.employmentType || [],
    boardStatus: metaData?.boardStatus || [],
    jobTypes: metaData?.jobTypes || [],
    practiceType: metaData?.practiceType || [],
    reasonForJobOpportunity: metaData?.reasonForJobOpportunity || [],
    handleGetMetaData,
  };
};
