import { useEffect, useState } from 'react';
import ApiService from '../../Services/ApiService';
import { useAuth } from '../AuthController';
import { useDispatch, useSelector } from 'react-redux';
import { setAddNewMessage, setConversations, setSingleConversation, setUpdateMessage } from '../../Store/Slices/ConversationSlice';
import ToastController from '../ToastController';
import { store } from '../../Store';
import UtilityController from '../UtilityController';
import { setMessage } from '../../Store/Slices/NotificationSlice';

class ConversationController {
  static MESSAGE_STATUS = {
    SENDING: 1,
    SENT: 2,
    FAILD: 3,
  };

  static onNewMessage({ chatId, message }) {
    store.dispatch(setAddNewMessage({ chatId, message }));
    store.dispatch(setMessage(true));
    ToastController.success('New message recieved.');
    // ReduxDispatchController.CONVERSATION.pushMessage(vehicleId ,offerId ,message)
  }

  static getAll(employerId, qryParams = { queryText: '', pageSize: 100, pageNumber: 1, archived: false }) {
    return ApiService.fetchData({
      url: `/employer/conversation/get-all-conversation/${employerId}`,
      method: 'get',
      params: qryParams,
    });
  }

  static deleteConversationMessage(conversationId, messageId) {
    return ApiService.fetchData({
      url: `/employer/conversation/delete-message/${conversationId}/${messageId}`,
      method: 'delete',
    });
  }
  static getSingle(conversationId) {
    return ApiService.fetchData({
      url: `/employer/conversation/get-single-conversation/${conversationId}`,
      method: 'get',
    });
  }

  static sendMessage(conversationId, message) {
    const data = {
      body: message.body,
      tempMsgId: message._id,
    };
    return ApiService.fetchData({
      url: `/employer/conversation/send-message/${conversationId}`,
      method: 'post',
      data,
    });
  }

  static updateMatchStatus(conversationId, status) {
    const data = {
      status: status,
    };
    return ApiService.fetchData({
      url: `/employer/match/update-match-status/${conversationId}`,
      method: 'patch',
      data,
    });
  }
}

export default ConversationController;

export const useConversations = (qryParams = { queryText: '', pageSize: 50, pageNumber: 1, archived: false }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  console.log(qryParams, '@query params');
  const dispatch = useDispatch();
  const conversations = useSelector(state => state.Conversation.conversations);
  const totalEmployerCount = conversations?.length > 0 && conversations?.reduce((total, employee) => total + employee.employerCount, 0);
  console.log(totalEmployerCount, '@total messages');
  function sortConversationsByLastMessageTime(conversations) {
    // Define a custom compare function to compare last message sent times
    console.log(conversations, '@fun data');
    const conversationsCopy = [...conversations];
    function compareLastMessageTime(a, b) {
      const lastMessageTimeA = a?.lastMessage && new Date(a?.lastMessage.sentTime);
      const lastMessageTimeB = b?.lastMessage && new Date(b?.lastMessage.sentTime);

      if (lastMessageTimeA < lastMessageTimeB) return 1; // Sorting in descending order
      if (lastMessageTimeA > lastMessageTimeB) return -1;
      return 0;
    }

    // Use the array sort method with the custom compare function
    const sortedConversations = conversationsCopy.sort(compareLastMessageTime);

    return sortedConversations;
  }
  const sortedConversations = sortConversationsByLastMessageTime(conversations || []);

  const handleGetConversations = async () => {
    try {
      setLoading(true);

      const data = await ConversationController.getAll(user?._id, qryParams);
      console.log(data, '@conversations...!');
      dispatch(setConversations(data?.data));
    } catch (error) {
      ToastController.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (!!jobId) {
    handleGetConversations();
    // }
  }, [qryParams?.queryText, qryParams?.pageNumber, qryParams?.pageSize, qryParams?.jobId, qryParams?.archived]);

  return {
    conversations: sortedConversations || [],
    totalEmployerCount: totalEmployerCount,
    // noOfPages: conversations?.noOfPages,
    loading,
    error,
  };
};

export const useSingleConversation = conversationId => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const singleConversation = useSelector(state => state.Conversation.singleConversation);

  // const conversationId = useParams().id;
  const handleGetSingleConversation = async () => {
    try {
      setLoading(true);
      const data = await ConversationController.getSingle(conversationId);
      dispatch(setSingleConversation(data?.data));
      console.log('single conversation res', data?.data);
    } catch (error) {
      setError(error);
      ToastController.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!conversationId) {
      handleGetSingleConversation();
    }
  }, [conversationId]);

  return {
    singleConversation: conversationId ? singleConversation : null,
    loading,
    error,
  };
};

export const useHandleMessages = () => {
  const randomId = UtilityController.randomIdGenerator();
  const dispatch = useDispatch();

  // const handleSendMessage = async (chatId, message, _callBack = () => false) => {
  const handleSendMessage = async (chatId, message) => {
    console.log('@chat running...!');
    const messageObj = {
      _id: randomId,
      body: message.body,
      status: ConversationController.MESSAGE_STATUS.SENDING,
      sentTime: new Date(),
      sender: 'employer',
    };

    dispatch(setAddNewMessage({ message: messageObj, chatId }));
    try {
      const res = await ConversationController.sendMessage(chatId, messageObj);
      console.log(res, '@chat res...!');
      const payload = {
        chatId,
        message: { ...res.data, status: ConversationController.MESSAGE_STATUS.SENT },
      };

      dispatch(setUpdateMessage(payload));
    } catch (error) {
      dispatch(setUpdateMessage({ chatId, message: { ...messageObj, status: ConversationController.MESSAGE_STATUS.FAILD } }));
      ToastController.error(error);
    } finally {
      // _callBack && _callBack();
    }
  };

  const handleReSendMessage = async (chatId, message, _callBack = () => false) => {
    const messageObj = {
      ...message,
      status: ConversationController.MESSAGE_STATUS.SENDING,
    };

    dispatch(setUpdateMessage({ message: messageObj, chatId }));
    try {
      const res = await ConversationController.sendMessage(chatId, messageObj);

      const payload = {
        chatId,
        message: { ...res.data, status: ConversationController.MESSAGE_STATUS.SENT, _id: message._id },
      };

      dispatch(setUpdateMessage(payload));
    } catch (error) {
      dispatch(setUpdateMessage({ chatId, message: { ...messageObj, status: ConversationController.MESSAGE_STATUS.FAILD } }));
      ToastController.error(error);
    } finally {
      _callBack && _callBack();
    }
  };

  return {
    handleSendMessage,
    handleReSendMessage,
  };
};
