import { NETWORK_ERROR } from '../Controllers/Errors';
import BaseService from './BaseService';

// 

const ApiService = {
  fetchData(param) {
    return new Promise((resolve, reject) => {
      BaseService({
        ...param,
        headers: {
          ...param?.headers,
        },
      })
        .then(response => {
          console.log('@api res....', response);
          if (response?.data?.success) {
            resolve(response?.data);
          } else {
            console.log('@api err....', response?.data?.error?.message);
            reject(response?.data?.error?.message);
          }
        })
        .catch(errors => {
          console.log('@api err....', errors);
          reject(NETWORK_ERROR);
        });
    });
  },
};

export default ApiService;
