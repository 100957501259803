import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  notifications: [],
  matchNotification: null,
  conversationNotification: null,
  messageNotification: null,
  notificationToken: null,
  fireBaseToken: null,
  newMessage: false,
};

export const NotificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    onApplyJob: (state, { payload }) => {
      state.matchNotification = payload;
    },

    onEmployeeRegistred: (state, { payload }) => {
      state.conversationNotification = payload;
    },

    onInviteAccept: (state, { payload }) => {
      state.conversationNotification = payload;
    },

    onInviteReject: (state, { payload }) => {
      state.conversationNotification = payload;
    },

    onMsgEmployer: (state, { payload }) => {
      state.messageNotification = payload;
    },

    setNotificationToken: (state, { payload }) => {
      state.notificationToken = payload;
    },

    setFirebaseToken: (state, { payload }) => {
      state.fireBaseToken = payload;
    },

    setNotifications: (state, { payload }) => {
      state.notifications = payload;
    },
    setMessage: (state, { payload }) => {
      console.log('@message paload..', payload);
      state.newMessage = payload;
    },
    setNotificationUpdate: (state, { payload }) => {
      const notificationIndex = state.notifications.notifications?.findIndex(n => n._id === payload?._id);
      if (notificationIndex >= 0) {
        state.notifications.notifications[notificationIndex] = payload;
      }
    },
  },
});

export const { setFirebaseToken, onApplyJob, setMessage, onEmployeeRegistred, setNotificationToken, onInviteAccept, onInviteReject, onMsgEmployer, setNotifications, setNotificationUpdate } =
  NotificationSlice.actions;

export default NotificationSlice.reducer;
