import { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import Layout from './Components/Layout';
import SplashScreen from './Components/Shared/SplashScreen';

import AuthController, { useAuth } from './Controllers/AuthController';
import ToastController from './Controllers/ToastController';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import NotificationController from './Controllers/NotificationController';
import { setNotificationToken } from './Store/Slices/NotificationSlice';
import { useDispatch } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useLocation } from 'react-router';
import MySocketController from './Controllers/SocketController';

function App() {
  const { user, session } = useAuth();
  const [loading, setLoading] = useState(false);
  const tokenFlag = useRef(false);
  const dispatch = useDispatch();

  const handleSyncUser = async () => {
    try {
      setLoading(true);
      const data = await AuthController.syncUser(user._id);
      setTimeout(() => {
        MySocketController.init();
      }, 2000);
      console.log(data, '@sync user...');
      AuthController.persistCredentials({ ...session, user: data?.data?.user });
    } catch (error) {
      console.log(error, `@sync error`);
      ToastController.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    AuthController.restorePersistedCredentials();
    console.log('called');
  }, []);

  const [isTokenFound, setTokenFound] = useState(false);
  useEffect(() => {
    if (!!user) {
      NotificationController.getToken(setTokenFound);
    }
  }, [user]);

  const handleToken = async () => {
    if (!tokenFlag.current) {
      try {
        const data = await NotificationController.saveToken(user?._id, isTokenFound);
        dispatch(setNotificationToken(data?.data?.token));
        tokenFlag.current = true;
        console.log(isTokenFound, data, '@token ....');
      } catch (error) {
        console.log(error, '@token error.!');
      }
    }
  };

  useEffect(() => {
    if (isTokenFound) {
      handleToken();
    }
  }, [isTokenFound, tokenFlag.current]);

  useEffect(() => {
    if (!!user) {
      handleSyncUser();
    }
  }, [session?.token]);

  // message listner
  NotificationController.onNotification(user?._id);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        style={{ zIndex: 999999999999999 }}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // className={"!capitalize"}
      />
      {loading ? <SplashScreen /> : <Layout />}
    </>
  );
}

export default App;
