import React, { memo, useMemo, Suspense, lazy } from 'react';
import { useAuth } from '../../Controllers/AuthController';
import SplashScreen from '../Shared/SplashScreen';
import { matchPath, useLocation } from 'react-router';
import { establishProfile, globalRoutes, wouldYouRatherProfile } from '../../Configs/routes.config';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
// import MiddleLayout from './MiddleLayout';
// import PublicLayoutV2 from './PublicLayoutV2'
// import EstablishProfileLayout from "./EstablishProfileLayout";
// import AuthLayout from "./AuthLayout"
const MiddleLayout = lazy(() => import('./MiddleLayout'));
const PublicLayoutV2 = lazy(() => import('./PublicLayoutV2'));
const EstablishProfileLayout = lazy(() => import('./EstablishProfileLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));
const WouldYouRatherLayout = lazy(() => import('./WouldYouRatherLayout'));

const Layout = () => {
  const { pathname } = useLocation();
  const { authenticated, session } = useAuth();

  console.log('@authenticated', authenticated);

  const AppLayout = useMemo(() => {
    if (globalRoutes.some(route => matchPath(route.path, pathname))) {
      return MiddleLayout;
    }
    if (!authenticated && establishProfile.some(route => matchPath(route.path, pathname))) {
      return MiddleLayout;
    }
    if (authenticated && wouldYouRatherProfile.some(route => matchPath(route.path, pathname))) {
      return WouldYouRatherLayout;
    }
    if (!authenticated) {
      return PublicLayoutV2;
    }
    if (authenticated && session?.code === 201) {
      return EstablishProfileLayout;
    }

    return AuthLayout;
  }, [authenticated, session?.code, pathname]);

  return (
    <Suspense fallback={<SplashScreen />}>
      <TransitionGroup>
        <CSSTransition classNames="fade" timeout={300}>
          <AppLayout />
        </CSSTransition>
      </TransitionGroup>
    </Suspense>
  );
};

export default memo(Layout);
