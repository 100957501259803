import axios from 'axios';
// import deepParseJson from "utils/deepParseJson";
import { API_KEY, BASE_URL } from '../Configs/api.config';
import AuthController from '../Controllers/AuthController';

const unauthorizedCode = [401];

const BaseService = axios.create({
  timeout: 100000,
  baseURL: BASE_URL,
});

BaseService.interceptors.request.use(
  config => {
    const persistData = AuthController.getPersistedCredentials();

    if (persistData?.token) {
      config.headers[`Authorization`] = persistData.token;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;

    if (response && unauthorizedCode.includes(response.status)) {
      //   store.dispatch(onSignOutSuccess());
    }

    return Promise.reject(error);
  }
);

export default BaseService;
