import React from 'react';
import authRoute from './authRoute';

export const publicRoutes = [...authRoute];

export const establishProfile = [
  {
    key: 'establishProfile',
    path: `/establish-profile`,
    component: React.lazy(() => import('../../View/EstablishProfile')),
    authority: [],
  },
];
export const wouldYouRatherProfile = [
  {
    key: 'WouldYouRather',
    path: '/would-you-rather',
    component: React.lazy(() => import('../../View/UpdateProfileV2/WouldYouRather')),
    authority: [],
  },
];

export const globalRoutes = [
  {
    key: 'Terms & Conditions',
    path: '/terms-conditions',
    component: React.lazy(() => import('../../View/TermsConditions')),
    authority: [],
  },
  {
    key: 'Delegate Access',
    path: '/delegate-access',
    component: React.lazy(() => import('../../View/DelegateAccess')),
    authority: [],
  },
  {
    key: 'Privacy Policy',
    path: '/privacy-policy',
    component: React.lazy(() => import('../../View/PrivacyPolicy')),
    authority: [],
  },

  {
    key: 'Licensing Agreement',
    path: '/licensing-agreement',
    component: React.lazy(() => import('../../View/LicensingAgreement')),
    authority: [],
  },
  {
    key: 'Subscription Agreement',
    path: '/subscription-agreement',
    component: React.lazy(() => import('../../View/SubscriptionAgreement')),
    authority: [],
  },
  {
    key: 'Doximity',
    path: '/auth/doximity/success',
    component: React.lazy(() => import('../../View/DoximitySuccess')),
    authority: [],
  },

  // {
  //   key: 'establishProfile',
  //   path: `/establish-profile`,
  //   component: React.lazy(() => import('../../View/EstablishProfile')),
  //   authority: [],
  // },
];

export const protectedRoutes = [
  // {
  //   key: 'Dashboard',
  //   path: '/dashboard',
  //   component: React.lazy(() => import('../../View/Dashboard')),
  //   authority: [],
  // },

  {
    key: 'establishProfile',
    path: `/establish-profile`,
    component: React.lazy(() => import('../../View/EstablishProfile')),
    authority: [``],
  },
  {
    key: 'Create Job',
    path: '/create-job',
    component: React.lazy(() => import('../../View/CreateJobViewV2')),
    authority: [],
  },
  {
    key: 'Edit Job',
    path: '/edit-job/:id',
    component: React.lazy(() => import('../../View/EditJobViewV2')),
    authority: [],
  },
  {
    key: 'ClaimBusinessProfile',
    path: `/claimBusinessProfile`,
    component: React.lazy(() => import('../../View/Auth/ClaimBusinessProfile')),
    authority: [],
  },
  {
    key: 'EditProfile',
    path: `/edit-profile`,
    component: React.lazy(() => import('../../View/UpdateProfileV2')),
    authority: [],
  },
  {
    key: 'Explore',
    path: `/explore`,
    component: React.lazy(() => import('../../View/ExploreView')),
    authority: [],
  },
  {
    key: 'Applicants',
    path: `/applicants`,
    component: React.lazy(() => import('../../View/ApplicantsView')),
    authority: [],
  },
  {
    key: 'Applicant Detail',
    path: `/applicants/:id`,
    component: React.lazy(() => import('../../View/ApplicantSingleView')),
    authority: [],
  },
  {
    key: 'Jobs',
    path: `/jobs`,
    component: React.lazy(() => import('../../View/JobsView')),
    authority: [],
  },
  {
    key: 'JobsResident',
    path: `/jobs-resident/:id`,
    component: React.lazy(() => import('../../View/JobsResidentView')),
    authority: [],
  },

  {
    key: 'JobsResident',
    path: `/jobs-resident`,
    component: React.lazy(() => import('../../View/JobsResidentView')),
    authority: [],
  },
  // {
  //   key: 'JobsResidents',
  //   path: `/matches`,
  //   component: React.lazy(() => import('../../View/JobsResidentView')),
  //   authority: [],
  // },
  {
    key: 'Home',
    path: `/home`,
    component: React.lazy(() => import('../../View/HomeView')),
    authority: [],
  },
  {
    key: 'Messages',
    path: `/messages`,
    component: React.lazy(() => import('../../View/MessageView')),
    authority: [],
  },
  {
    key: 'WouldYouRather',
    path: '/would-you-rather',
    component: React.lazy(() => import('../../View/UpdateProfileV2/WouldYouRather')),
    authority: [],
  },
  {
    key: 'WouldYouRather',
    path: '/edit-profile/would-you-rather',
    component: React.lazy(() => import('../../View/UpdateProfileV2/WouldYouRather')),
    authority: [],
  },
];
